const activeCollateral = {
    columns: [
        {
            label: 'Name',
            field: 'name',
            sort: 'asc'
        },
        {
            label: 'Type',
            field: 'type',
            sort: 'asc'
        },
        {
            label: 'Description',
            field: 'description',
            sort: 'asc'
        },
        {
            label: 'Example',
            field: 'example',
            sort: 'asc'
        }
    ],
    rows: [
        {
            'name': 'TAG',
            'kind': 'String',
            'description': 'TAG from device',
            'example': '3B2DD0F',
        },
        {
            'name': 'TAG',
            'kind': 'String',
            'description': 'TAG from device',
            'example': '3B2DD0F',
        }
    ]
}

export default activeCollateral;