import React from "react";
import Sidebar from "../components/navigation/Sidebar";
import ApiCard from "../components/ui/ApiCard";
import routes from "../navigation/routes";
import ReactJson from "react-json-view";
import responseGetCollateral from "../assets/json/response-get-collateral";
import ToastHelper from "../helpers/toast-helper";
import PostmanImageSimple from "../assets/images/docs/2298e4.svg"
import AppLifecycleImage from "../assets/images/docs/6c452f.svg"
import AppLifecycleImageMobile from "../assets/images/docs/016102.svg"
import activeCollateral from "../assets/json/params/active-collateral";
import http from "../helpers/constants/http";
import Card from "react-bootstrap/Card";
import Header from "../components/navigation/Header";
import Translator from "../components/i18n/Translator";
import auth from "../assets/json/params/auth";
import changeDueDate from "../assets/json/params/change-due-date";
import getAllCollaterals from "../assets/json/params/get-all-collaterals";
import productItemId from "../assets/json/params/product_item_id";

class Docs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCardVisible: false
        };
    }

    handleDownload = () => {
        const collection = require('../assets/json/collection.json');
        const file = new Blob([JSON.stringify(collection, null, 2)], {type: 'application/json'});
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = 'collection.json';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        if (file.size > 0) {
            ToastHelper.showSuccess('File downloaded successfully');
        } else {
            ToastHelper.showError('Failed to download file');
        }
    };


    render() {

        return <>
            <Header/>
            <Sidebar/>
            <main>
                <h1><Translator path={'docs.docs'}/></h1>
                <br/> <br/> <br/>

                <section id={routes.HOW_IT_WORKS}>
                    <h2><Translator path={'docs.how_it_works'}/></h2>
                    <br/>
                    <Card className="custom-card">
                        <Card.Body>
                            <Card.Title><Translator path={'docs.intro'}/></Card.Title>
                            <Translator path={'docs.purpose'}/>
                        </Card.Body>
                    </Card>
                    <br/>
                    <Card className="custom-card">
                        <Card.Body>
                            <Card.Title><Translator path={'docs.lifecycle'}/></Card.Title>
                            <img className="responsive-image desktop-image" src={AppLifecycleImage}
                                 alt={"Application lifecycle"}/>
                            <img className="responsive-image mobile-image" src={AppLifecycleImageMobile}
                                 alt={"Application lifecycle"}/>
                            <Translator path={'docs.lifecycle_details'}/>
                        </Card.Body>
                    </Card>

                </section>

                <br/> <br/> <br/>

                <section className="api-section">
                    <h2><Translator path={'sidebar.getting_started'}/></h2>
                    <br/>

                    <div className="card-container">

                        <ApiCard
                            method={http.POST}
                            id={routes.API_KEY}
                            title={<Translator path={'docs.generate_key'}/>}
                            text={<Translator path={'docs.api_key'}/>}
                            columns={auth.columns}
                            rows={auth.rows}
                            endpoint="oauth"
                            path={
                                <div>oauth</div>
                            }
                            response={
                                <div style={{display: "grid"}}>
                                    <p className="response-code"><i className="bi bi-check-circle-fill"></i> Status -
                                        200 OK
                                    </p>
                                    {/*                                    <ReactJson src={responseGetCollateral} name={false}/>*/}
                                </div>
                            }
                        />

                        {this.state.isCardVisible ?
                            <ApiCard
                                method={http.PUT}
                                id={routes.ACTIVATE}
                                title="Activate Collateral"
                                text="Endpoint that activates the collateral."
                                columns={activeCollateral.columns}
                                rows={activeCollateral.rows}
                                endpoint="TAG/activate/executor"
                                path={
                                    <div><b className="endpoint-param">TAG</b>/activate/executor</div>
                                }
                                response={
                                    <div style={{display: "grid"}}>
                                        <p className="response-code"><i className="bi bi-check-circle-fill"></i> Status
                                            -
                                            200 OK
                                        </p>
                                        <ReactJson src={responseGetCollateral} name={false}/>
                                    </div>
                                }
                            />
                            :
                            null
                        }

                        <ApiCard
                            method={http.GET}
                            id={routes.GET_COLLATERAL}
                            title="Get Collateral"
                            text={<Translator path={'docs.get_collateral'}/>}
                            endpoint="TAG/executor"
                            path={
                                <div><b className="endpoint-param">TAG</b>/executor</div>
                            }
                            response={
                                <div style={{display: "grid"}}>
                                    <p className="response-code"><i className="bi bi-check-circle-fill"></i> Status -
                                        200 OK
                                    </p>
                                    <ReactJson src={responseGetCollateral} name={false}/>
                                </div>
                            }
                        />

                        <ApiCard
                            method={http.PUT}
                            id={routes.DUE_DATE}
                            title="Change Due Date"
                            text={<Translator path={'docs.change_due_date'}/>}
                            columns={changeDueDate.columns}
                            rows={changeDueDate.rows}
                            endpoint="TAG/duedate/executor?newDueDate=yyyy-mm-dd"
                            path={
                                <div>
                                    <b className="endpoint-param">TAG</b>
                                    /duedate/executor?newDueDate=<b className="endpoint-param">yyy-mm-dd</b>
                                </div>
                            }
                            response={
                                <div style={{display: "grid"}}>
                                    <p className="response-code"><i className="bi bi-check-circle-fill"></i> Status -
                                        200 OK
                                    </p>
                                </div>
                            }
                        />

                        <ApiCard
                            method={http.PUT}
                            id={routes.RELEASE}
                            title="Release collateral"
                            text={<Translator path={'docs.release_collateral'}/>}
                            endpoint="TAG/release/executor"
                            path={
                                <div>
                                    <b className="endpoint-param">TAG</b>
                                    /release/executor
                                </div>
                            }
                            response={
                                <div style={{display: "grid"}}>
                                    <p className="response-code"><i className="bi bi-check-circle-fill"></i> Status -
                                        200 OK
                                    </p>
                                </div>
                            }
                        />
                        <ApiCard
                            id={routes.GET_ALL}
                            title="Get All collaterals"
                            text={<Translator path={'docs.get_all_collaterals'}/>}
                            columns={getAllCollaterals.columns}
                            rows={getAllCollaterals.rows}
                            endpoint="PRODUCT_CODE/product?size=100&page=0"
                            path={
                                <div>
                                    <b className="endpoint-param">PRODUCT_CODE</b>
                                    /product?size=<b className="endpoint-param">100</b>&page=<b
                                    className="endpoint-param">0</b>
                                </div>
                            }
                            response={
                                <div style={{display: "grid"}}>
                                    <p className="response-code"><i className="bi bi-check-circle-fill"></i> Status -
                                        200 OK
                                    </p>
                                    <ReactJson src={responseGetCollateral} name={false}/>
                                </div>
                            }
                        />
                        <ApiCard
                            method={http.GET}
                            id={routes.GET_BY_TAG}
                            title="Get All collaterals by TAG"
                            text={<Translator path={'docs.get_all_by_tag'}/>}
                            params="TAG - Tag from collateral"
                            endpoint="TAG/executor/all"
                            path={
                                <div>
                                    <b className="endpoint-param">TAG</b>
                                    /executor/all
                                </div>
                            }
                            response={
                                <div style={{display: "grid"}}>
                                    <p className="response-code"><i className="bi bi-check-circle-fill"></i> Status -
                                        200 OK
                                    </p>
                                    <ReactJson src={responseGetCollateral} name={false}/>
                                </div>
                            }
                        />
                        <ApiCard
                            method={http.PUT}
                            id={routes.UPDATE_PRODUCT_ITEM_ID}
                            title="Update Product Item Id"
                            text={<Translator path={'docs.update_product_item_id'}/>}
                            columns={productItemId.columns}
                            rows={productItemId.rows}
                            endpoint="TAG/executor/update-product-item-id?productItemId=PRODUCT_ITEM_ID&productCode=PRODUCT_CODE"
                            path={
                                <div>
                                    <b className="endpoint-param">TAG</b>
                                    /executor/update-product-item-id?productItemId=<b
                                    className="endpoint-param">PRODUCT_ITEM_ID</b>&productCode=<b
                                    className="endpoint-param">PRODUCT_CODE</b>
                                </div>
                            }
                            response={
                                <div style={{display: "grid"}}>
                                    <p className="response-code"><i className="bi bi-check-circle-fill"></i> Status -
                                        200 OK
                                    </p>
                                </div>
                            }
                        />

                        <section className="postman-section-mobile-first" id={routes.DOWNLOAD_COLLECTION}>
                            <img src={PostmanImageSimple} alt={"Postman"}/>
                            <p><Translator path={'docs.collection'}/></p>
                            <button
                                onClick={() => this.handleDownload()}
                                type="submit"
                                className="short-button">
                                <i className="bi bi-download"></i> <Translator path={'docs.download'}/>
                            </button>
                        </section>
                    </div>
                </section>

            </main>
        </>
    }
}

export default Docs;