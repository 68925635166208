export default {
    translations: {
        toast_helper: {
            send_email: {
                error: 'The message could not be sent, please try again',
                success: 'The message was sent successfully',
            }
        },
        navbar: {
            home: 'Home',
            docs: 'Docs',
            contact: 'Contact us',
            language: 'Language',
            about_us: 'About us',
            product: 'Product'
        },
        sidebar: {
            home: 'Home',
            how_it_works: 'How it works',
            download: 'Download',
            getting_started: 'Getting started'
        },
        endpoint: {
            get_collateral: 'Get',
            change_due_date: 'Lock',
            release_collateral: 'Release',
            get_all_collaterals: 'Get All',
            get_all_by_tag: 'Get by TAG',
            update_product_item_id: 'Update Id',
            params: 'Params',
            endpoint: 'Endpoint',
            expected_response: 'Expected response'
        },
        docs: {
            docs: 'Docs',
            how_it_works: 'How it works',
            intro: 'Introduction',
            purpose: 'The purpose of this document is to explain with more details about the Globetek solution for mobile devices. You will find an overview about the application lifecycle, how to use the API, getting your credentials, activating and releasing the collateral.',
            lifecycle: 'Collateral Application Lifecycle',
            lifecycle_details: 'Basically, our application follows a logic to work, they have a "lifecycle" which means that this app will be configured, then will be used for that purpose and in the end the app will be "released", will be "deactivated". Because the purpose was done already.',
            get_collateral: 'Endpoint that returns the collateral.',
            change_due_date: 'Endpoint that changes the collateral due date.',
            release_collateral: 'Endpoint used to release the collateral.',
            get_all_collaterals: 'Endpoint that returns all collaterals from a partner.',
            get_all_by_tag: 'Endpoint that returns all collaterals by tag.',
            update_product_item_id: 'Endpoint that updates the product item id, the product item id can be used like an intern id from your system.',
            collection: 'Download our Postman collection',
            download: 'Download',
            generate_key: 'Generate API Key',
            api_key: 'Endpoint used to get an access token. It is required to provide your credentials (token) to use every endpoint.',
            provided_by: 'Provided by admin'
        },
        home: {
            digital_solution: 'Multi functional digital solution',
            unique_needs: 'The solution that fit the unique needs of your business.',
            reduce_credit_risk: 'Reduce credit risk',
            approval_rates: 'Increase approval rates',
            reduce_fraud: 'Reduce fraud',
            earn_money: 'Earn money while your customers watch ads',
            payment: 'Achieve the repayment of your loans by using our digital blocking solutions',
            how_it_works: 'How it works',
            complete_solution: 'The most complete digital solution! Easy to install and flexible to implement',
            set_up: 'Set-up',
            rules: 'Lender specifies lock rules',
            installation: 'Installation',
            apk_installation: 'Customer side-loads the apk on the device and allows permissions',
            locking: 'Locking / Unlocking',
            unlock_rules: 'Device unlocked upon payment',
            book_a_demo: 'Book a demo!',
            our_competitive: 'Our competitive',
            advantages: 'Advantages',
            device_coverage: 'Device coverage',
            brands: 'More brands supported Samsung, LG, Motorola and Xiaomi Others pending...',
            customizable: 'Customizable',
            not_required: 'More flexible install process, which doesn’t require a device reset or a reboot. No IMEI number required.',
            flexible: 'Flexible Lock',
            more_flexible: 'More Flexible',
            lock_implementation: 'Lock implementation',
            meet: 'Meet',
            our_solutions: 'Our solutions',
            learn_more: 'Learn more about our lock solution and achieve higher returns from the best combination for your business model.',
            new: 'New',
            ad_lock: 'Generate revenue while your customers\' phone is locked. Advertisements will be played every time the end user accesses any app and this will allow them to monetize their blocking.',
            full_lock: 'Achieve the repayment of your loan installments. Today no one would allow their phone to be locked. Your customers will pay their fee without hesitation.',
            partial_lock: 'Create a black list of those apps you want to block. In this way, your clients will not be able to access them while the partial blocking is active.',
            combined_lock: 'Achieve the repayment of your installments by identifying the best combination for your clients. A lock tailored to your business model.',
            credit_score: 'Collect data from your leads and reduce the risk in granting loans. Create your own score from the information received.',
            try_for_free: 'Try for free',
            plans: 'Plans',
            take_control: 'Take control of the customer journey. Activate the technology that best suits your business!',
            privacy_policy: 'Privacy Policy',
            terms_of_service: 'Terms of Service',
            our_partners: 'Our partners',
            solution_for_partners: 'We provide a wide range of lock solutions, with a focus on the unique needs of each country and partner.'
        },
        slide: {
            digital_solution: 'DIGITAL SOLUTION',
            efficiency: 'Efficiency in Credit Origination and Default Reduction',
            learn_more: 'Learn More',
            fraud: 'Fraud Reduction and Enhanced Security',
            flexibility: 'Flexibility and Control at Your Command',
        },
        commercial: {
            try: 'Try for free!',
            commercial_proposal: 'Commercial Proposal',
            free_trial: 'Free trial',
            free_trial_txt: 'Get a free trial period to see the benefits of our solution',
            free_trial_bolder: 'in practice.',
            indicative: 'Indicative',
            indicative_txt_1: 'One-time payment - per active device. 6.00 USD.',
            indicative_txt_2: 'Monthly payment - actual values TBD, dependent on volume. 0.60 USD.',
            get_a_demo: 'Get a demo',
            get_a_demo_txt: 'Contact us and get a demo.',
            get_a_demo_bolder: 'See how it works.'
        },
        product: {
            step_one: 'Try one! switch any time',
            step_two: 'Tap to see a little closer',
            ad_display: 'AD Display',
            full_lock: 'Full Lock',
            partial_lock: 'Partial Lock',
            ad_display_preview: 'Our AD display option is perfect for monetizing with our cutting-edge technology. You can choose between a traditional ad or an image of your own that can be closed after a few seconds.',
            full_lock_preview: 'The Full Lock feature enables you to globally restrict the functionalities of your mobile phone, allowing only phone calls and access to your website (optional).',
            partial_lock_preview: 'Partial Lock allows you to restrict specific features of your mobile phone based on a customized list.',
            learn_more: 'Learn More',
            benefits_title: 'Manage your digital solution',
            benefit_customize: 'Customize the app with your brand colors and texts that make sense to you',
            benefit_adm_panel: 'Control everything through administrative panel or api',
            benefit_installation: 'Install by QR Code (by using Android Management System) or install our App.',
            benefit_no_mei_required: 'No IMEI or Factory reset required',
            benefit_switch_easily: 'Switch features easily',
            header_feature: 'Features',
            ad_frequency: 'Control the frequency of AD',
            list_of_apps: 'Utilize a list of allowed apps',
            hard_lock: 'Completely block',
            ads_money: 'Earn money with ads',
            custom_message: 'Customize your message',
            details_title: 'Meet the details',
            ad_display_detailed: 'Our AD display option is perfect for monetizing with our cutting-edge technology. You can choose between a traditional ad or an image of your own that can be closed after a few seconds. The screen is displayed to the customer periodically according to their settings. Additionally, our product doesn\'t interfere in emergency situations.',
            full_lock_detailed: 'Partial Lock allows you to restrict specific features of your mobile phone based on a customized list. When the customer selects a disallowed application, a pop-up appears on their screen, informing them that the feature is not available.',
            partial_lock_detailed: 'The Full Lock feature enables you to globally restrict the functionalities of your mobile phone, allowing only phone calls and access to your website (optional). The locking, like other options, can be programmed, allowing you to remotely lock and unlock it whenever desired.',
        },
        about_us: {
            who_are_we: 'Who are we?',
            introduction: 'We are a motivated tech team focused on innovating digital solutions. Our journey began in Brazil with a financial breakthrough that has the potential to yield awesome results.',
            grow: 'We are here to grow your numbers!',
            tech: '#tech',
            innovation: '#innovation',
            security: '#security',
            results: 'Our real results on Brazilian Subprime Lander',
            risk_reduction: 'Significant risk reduction for different types of credit profiles see the numbers!',
            loans: 'Loans funded using phone lock technology',
            defaults: 'Decrease in defaults',
            approvals: 'Lift in approvals',
            lander_on_brazil: 'Biggest Subprime Lander on Brazil',
            brazilian_case: 'We are born to democratize access to credit in Brazil, and for this purpose, we offer the best conditions for all profiles. And we are able to do this thanks to Globetek\'s technology. With technology, we can control our risk, expanding the number of clients and returns. Technology has been essential for our growth. \n To give you an idea of how impactful technology was, our sales numbers increased by 3 times, and there was an exponential growth. Technology addressed our major challenge of managing delinquency, and our company specifically dealt with a high-risk group.'
        },
        faq: {
            faq: 'FAQ',
            installation: 'How to install the application',
            installation_answer: 'You will install an APK, we also provide a step by step guide to help you on this process.',
            how_to_lock: 'How to lock a device',
            how_to_lock_answer: 'You can easily lock and unlock by using our administrative panel',
            billing: 'How the billing process works',
            billing_answer: 'In the beginning we offer a free trial for our partners, when the partner decides to work with us, we only require an activation fee per device and a cheaper maintenance fee for each device.',
            factory_reset: 'Needs factory reset?',
            factory_reset_answer: 'Needs factory reset?',
            compatible_devices: 'Compatible devices',
            compatible_devices_answer: 'We work with Samsung, Motorola, Xiaomi and LG',
            pii: 'Does the Globetek app collect personally identifiable information (PII)',
            pii_answer: 'The Globetek app only gathers device-specific information that cannot be linked to an individual. As a result, it is not possible to identify a person based on any collected information.',
        },
        contact: {
            title: 'Contact form',
            name: 'Name',
            phone: 'Phone',
            message: 'Message',
            submit: 'Submit',
        },
        footer: {
            waiting: 'What are you waiting for?',
            location: 'Location',
            peru: 'Peru',
            colombia: 'Colombia',
            guatemala: 'Guatemala',
            uzbekistan: 'Uzbekistan',
            bolivia: 'Bolivia',
            latvia: 'Latvia',
            pages: 'Pages',
            solution: 'Solution',
            product: 'Product',
            about_us: 'About us',
            documentation: 'Documentation',
            contact_us: 'Contact us',
            privacy_policy: 'Privacy policy',
            terms_of_service: 'Terms of service',
            globetek: '© Globetek Holdings',
        }
    }
}