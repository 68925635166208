export default {
    translations: {
        toast_helper: {
            send_email: {
                error: 'A mensagem não pôde ser enviada, por favor tente novamente',
                success: 'A mensagem foi enviada com sucesso',
            }
        },
        navbar: {
            home: 'Início',
            docs: 'Documentos',
            contact: 'Contate-nos',
            language: 'Idioma',
            about_us: 'Sobre nós',
            product: 'Produto'
        },
        sidebar: {
            home: 'Início',
            how_it_works: 'Como funciona',
            download: 'Baixar',
            getting_started: 'Primeiros passos'
        },
        endpoint: {
            get_collateral: 'Obter',
            change_due_date: 'Bloquear',
            release_collateral: 'Liberar',
            get_all_collaterals: 'Obter Todos',
            get_all_by_tag: 'Obter por TAG',
            update_product_item_id: 'Atualizar ID',
            params: 'Parâmetros',
            endpoint: 'Ponto de Acesso',
            expected_response: 'Resposta Esperada'
        },
        docs: {
            docs: 'Documentos',
            how_it_works: 'Como funciona',
            intro: 'A finalidade deste documento é explicar com mais detalhes a solução Globetek para dispositivos móveis. Você encontrará uma visão geral do ciclo de vida do aplicativo, como usar a API, obter suas credenciais, ativar e liberar o colateral.',
            purpose: 'O objetivo deste documento é explicar com mais detalhes a solução Globetek para dispositivos móveis. Você encontrará uma visão geral do ciclo de vida do aplicativo, como usar a API, obter suas credenciais, ativar e liberar o colateral.',
            lifecycle: 'Ciclo de Vida do Aplicativo de Colateral',
            lifecycle_details: 'Basicamente, nosso aplicativo segue uma lógica de funcionamento; eles têm um "ciclo de vida", o que significa que este aplicativo será configurado, depois usado para esse propósito e, no final, o aplicativo será "liberado", será "desativado". Porque o propósito já foi cumprido.',
            get_collateral: 'Ponto de acesso que retorna o colateral.',
            change_due_date: 'Ponto de acesso que altera a data de vencimento do colateral.',
            release_collateral: 'Ponto de acesso usado para liberar o colateral.',
            get_all_collaterals: 'Ponto de acesso que retorna todos os colaterais de um parceiro.',
            get_all_by_tag: 'Ponto de acesso que retorna todos os colaterais por tag.',
            update_product_item_id: 'Ponto de acesso que atualiza o ID do item do produto; o ID do item do produto pode ser usado como um ID interno do seu sistema.',
            collection: 'Baixe nossa coleção no Postman',
            download: 'Baixar',
            generate_key: 'Gerar Chave de API',
            api_key: 'Ponto de acesso usado para obter um token de acesso. É necessário fornecer suas credenciais (token) para usar cada ponto de acesso.',
            provided_by: 'Fornecido pelo administrador'
        },
        home: {
            digital_solution: 'Solução digital multifuncional',
            unique_needs: 'A solução que atende às necessidades únicas do seu negócio.',
            reduce_credit_risk: 'Reduzir o risco de crédito',
            approval_rates: 'Aumentar as taxas de aprovação',
            reduce_fraud: 'Reduzir a fraude',
            earn_money: 'Ganhar dinheiro enquanto seus clientes veem anúncios',
            payment: 'Alcançar o pagamento de seus empréstimos usando nossas soluções digitais de bloqueio',
            how_it_works: 'Como funciona',
            complete_solution: 'A solução digital mais completa! Fácil de instalar e flexível de implementar',
            set_up: 'Configuração',
            rules: 'O credor especifica as regras de bloqueio',
            installation: 'Instalação',
            apk_installation: 'O cliente instala o APK no dispositivo e permite as permissões',
            locking: 'Bloqueio / Desbloqueio',
            unlock_rules: 'O dispositivo é desbloqueado após o pagamento',
            book_a_demo: 'Agende uma demonstração!',
            our_competitive: 'Nossas vantagens competitivas',
            advantages: 'Vantagens',
            device_coverage: 'Cobertura de dispositivos',
            brands: 'Mais marcas suportadas: Samsung, LG, Motorola e Xiaomi. Outras pendentes...',
            customizable: 'Personalizável',
            not_required: 'Processo de instalação mais flexível, que não requer reinicialização ou reset de fábrica do dispositivo. Número de IMEI não é necessário.',
            flexible: 'Bloqueio Flexível',
            more_flexible: 'Mais Flexível',
            lock_implementation: 'Implementação de Bloqueio',
            meet: 'Conheça',
            our_solutions: 'Nossas soluções',
            learn_more: 'Saiba mais sobre nossa solução de bloqueio e alcance retornos maiores com a melhor combinação para o seu modelo de negócios.',
            new: 'Novo',
            ad_lock: 'Gere receita enquanto o telefone de seus clientes está bloqueado. Anúncios serão reproduzidos toda vez que o usuário final acessar qualquer aplicativo e isso permitirá que eles monetizem o bloqueio.',
            full_lock: 'Alcance o pagamento das parcelas do seu empréstimo. Hoje em dia, ninguém permitiria que seu telefone fosse bloqueado. Seus clientes pagarão sua taxa sem hesitação.',
            partial_lock: 'Crie uma lista negra dos aplicativos que você deseja bloquear. Dessa forma, seus clientes não poderão acessá-los enquanto o bloqueio parcial estiver ativo.',
            combined_lock: 'Alcance o pagamento das parcelas identificando a melhor combinação para seus clientes. Um bloqueio adaptado ao seu modelo de negócios.',
            credit_score: 'Colete dados de seus leads e reduza o risco na concessão de empréstimos. Crie sua própria pontuação a partir das informações recebidas.',
            try_for_free: 'Experimente gratuitamente',
            plans: 'Planos',
            take_control: 'Assuma o controle da jornada do cliente. Ative a tecnologia que melhor se adapte ao seu negócio!',
            privacy_policy: 'Política de Privacidade',
            terms_of_service: 'Termos de Serviço',
            our_partners: 'Nossos parceiros',
            solution_for_partners: 'Oferecemos uma ampla gama de soluções de bloqueio, com foco nas necessidades únicas de cada país e parceiro.'
        },
        slide: {
            digital_solution: 'SOLUÇÃO DIGITAL',
            efficiency: 'Eficiência na Originação de Crédito e Redução de Inadimplência',
            learn_more: 'Saiba Mais',
            fraud: 'Redução de Fraude e Segurança Aprimorada',
            flexibility: 'Flexibilidade e Controle ao Seu Alcance',
        },
        commercial: {
            try: 'Experimente gratuitamente!',
            commercial_proposal: 'Proposta Comercial',
            free_trial: 'Teste grátis',
            free_trial_txt: 'Obtenha um período de teste gratuito para ver os benefícios de nossa solução',
            free_trial_bolder: 'na prática.',
            indicative: 'Indicativo',
            indicative_txt_1: 'Pagamento único - por dispositivo ativo. 6,00 USD.',
            indicative_txt_2: 'Pagamento mensal - valores reais a serem determinados, dependendo do volume. 0,60 USD.',
            get_a_demo: 'Obtenha uma demonstração',
            get_a_demo_txt: 'Entre em contato conosco e obtenha uma demonstração.',
            get_a_demo_bolder: 'Veja como funciona.'
        },
        product: {
            step_one: 'Experimente um! Troque a qualquer momento',
            step_two: 'Toque para ver mais de perto',
            ad_display: 'Exibição de Anúncios',
            full_lock: 'Bloqueio Completo',
            partial_lock: 'Bloqueio Parcial',
            ad_display_preview: 'Nossa opção de exibição de anúncios é perfeita para monetização com nossa tecnologia de ponta. Você pode escolher entre um anúncio tradicional ou uma imagem própria que pode ser fechada após alguns segundos.',
            full_lock_preview: 'A função de Bloqueio Completo permite restringir globalmente as funcionalidades do seu telefone móvel, permitindo apenas chamadas telefônicas e acesso ao seu site (opcional).',
            partial_lock_preview: 'O Bloqueio Parcial permite restringir funções específicas do seu telefone móvel com base em uma lista personalizada.',
            learn_more: 'Saiba mais',
            benefits_title: 'Gerencie sua solução digital',
            benefit_customize: 'Personalize o aplicativo com as cores da sua marca e textos que façam sentido para você',
            benefit_adm_panel: 'Controle tudo por meio do painel administrativo ou API',
            benefit_installation: 'Instale por código QR (usando o Sistema de Gerenciamento Android) ou instale nosso aplicativo.',
            benefit_no_mei_required: 'Não é necessário IMEI ou redefinição de fábrica',
            benefit_switch_easily: 'Mude as funcionalidades facilmente',
            header_feature: 'Recursos',
            ad_frequency: 'Controle a frequência dos anúncios',
            list_of_apps: 'Utilize uma lista de aplicativos permitidos',
            hard_lock: 'Bloqueio completo',
            ads_money: 'Ganhe dinheiro com anúncios',
            custom_message: 'Personalize sua mensagem',
            details_title: 'Conheça os detalhes',
            ad_display_detailed: 'Nossa opção de exibição de anúncios é perfeita para monetização com nossa tecnologia de ponta. Você pode escolher entre um anúncio tradicional ou uma imagem própria que pode ser fechada após alguns segundos. A tela é exibida periodicamente ao cliente de acordo com as configurações. Além disso, nosso produto não interfere em situações de emergência.',
            full_lock_detailed: 'O Bloqueio Completo permite restringir funções específicas do seu telefone móvel com base em uma lista personalizada. Quando o cliente seleciona um aplicativo não permitido, uma janela pop-up aparece em sua tela, informando que a funcionalidade não está disponível.',
            partial_lock_detailed: 'A função de Bloqueio Completo permite restringir globalmente as funcionalidades do seu telefone móvel, permitindo apenas chamadas telefônicas e acesso ao seu site (opcional). O bloqueio, assim como outras opções, pode ser programado, permitindo bloquear e desbloquear remotamente quando desejar.',
        },
        about_us: {
            who_are_we: 'Quem somos?',
            introduction: 'Somos uma equipe de tecnologia motivada focada em inovar soluções digitais. Nossa jornada começou no Brasil com uma quebra financeira que tem o potencial de gerar resultados incríveis.',
            grow: 'Estamos aqui para fazer crescer seus números!',
            tech: '#tecnologia',
            innovation: '#inovação',
            security: '#segurança',
            results: 'Nossos resultados reais no Prestamista Subprime Brasileiro',
            risk_reduction: 'Redução significativa de risco para diferentes perfis de crédito. Veja os números!',
            loans: 'Empréstimos financiados usando tecnologia de bloqueio de telefone',
            defaults: 'Redução de inadimplência',
            approvals: 'Aumento nas aprovações',
            lander_on_brazil: 'O maior Prestamista Subprime do Brasil',
            brazilian_case: 'Nascemos para democratizar o acesso ao crédito no Brasil, e para isso, oferecemos as melhores condições para todos os perfis. E podemos fazer isso graças à tecnologia da Globetek. Com a tecnologia, podemos controlar nosso risco, ampliar o número de clientes e retornos. A tecnologia tem sido essencial para nosso crescimento. \n Para você ter uma ideia de quão impactante foi a tecnologia, nossas vendas aumentaram 3 vezes e houve um crescimento exponencial. A tecnologia resolveu nosso maior desafio de gerenciar a inadimplência, e nossa empresa em particular lidou com um grupo de alto risco.'
        },
        faq: {
            faq: 'Perguntas Frequentes',
            installation: 'Como instalar o aplicativo',
            installation_answer: 'Você irá instalar um APK; também fornecemos um guia passo a passo para ajudá-lo neste processo.',
            how_to_lock: 'Como bloquear um dispositivo',
            how_to_lock_answer: 'Você pode bloquear e desbloquear facilmente usando nosso painel administrativo',
            billing: 'Como funciona o processo de faturamento',
            billing_answer: 'No início, oferecemos um teste gratuito para nossos parceiros; quando o parceiro decide trabalhar conosco, só requeremos uma taxa de ativação por dispositivo e uma taxa de manutenção mais econômica por cada dispositivo.',
            factory_reset: 'Necessita de reinicialização de fábrica?',
            factory_reset_answer: 'Necessita de reinicialização de fábrica?',
            compatible_devices: 'Dispositivos compatíveis',
            compatible_devices_answer: 'Trabalhamos com Samsung, Motorola, Xiaomi e LG',
            pii: 'O aplicativo Globetek coleta informações de identificação pessoal (PII)?',
            pii_answer: 'O aplicativo Globetek coleta apenas informações específicas do dispositivo que não podem ser vinculadas a uma pessoa. Portanto, não é possível identificar uma pessoa com base nas informações coletadas.',
        },
        contact: {
            title: 'Formulário de Contato',
            name: 'Nome',
            phone: 'Telefone',
            message: 'Mensagem',
            submit: 'Enviar',
        },
        footer: {
            waiting: 'O que você está esperando?',
            location: 'Localização',
            peru: 'Peru',
            colombia: 'Colômbia',
            guatemala: 'Guatemala',
            uzbekistan: 'Uzbequistão',
            bolivia: 'Bolívia',
            latvia: 'Letônia',
            pages: 'Páginas',
            solution: 'Solução',
            product: 'Produto',
            about_us: 'Sobre nós',
            documentation: 'Documentação',
            contact_us: 'Contate-nos',
            privacy_policy: 'Política de Privacidade',
            terms_of_service: 'Termos de Serviço',
            globetek: '© Globetek Holdings',
        }
    }
}
