const responseGetCollateral = {
  createdAt: "2022-08-22T12:38:45.818825",
  updatedAt: "2022-08-24T04:25:47.541335",
  id: 123,
  partyId: 456,
  productCode: "partner",
  productItemId: 789,
  state: 4,
  details: {}
};

export default responseGetCollateral;
